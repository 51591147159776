<template>
  <div class="pr-8 space-y-4">
    <ProCheckbox
      v-model="is_altimetrie_active"
      :val="is_altimetrie_active"
      id="is_altimetrie_active"
    >
      <div class="flex items-center justify-center">
        <div class="w-4 h-4 bg-promy-green-350 rounded-md mr-2" />
        Profil altimétrique
      </div>
    </ProCheckbox>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import parcelle from '@/mixins/parcelle'
import Mapbox from '@/mixins/map/mapbox'

export default {
  mixins: [parcelle, Mapbox],
  computed: {
    ...mapGetters({
      map: 'map/map',
      altimetries: 'parcelle/altimetries',
      is_altimetries_loading: 'parcelle/is_altimetries_loading',
    }),
  },
  data() {
    return {
      is_altimetrie_active: false,
      markers_altimetrie: [],
    }
  },

  methods: {
    addCornerCrossMarkersAltimetrie() {
      if (this.is_altimetrie_active) {
        this.removeMarkers(this.markers_altimetrie)
        this.altimetries.forEach((altimetrie) => {
          let temp_marker = this.addSingleCornerWithElevation(
            altimetrie.geometry.coordinates,
          )
          this.markers_altimetrie.push(temp_marker)
          temp_marker.elevation = altimetrie.properties.z
          this.addPopupWithElevation(temp_marker)
        })
      } else {
        this.removeMarkers(this.markers_altimetrie)
      }
    },
    addSingleCornerWithElevation(corner) {
      let marker_el = this.addMarkerCircleToMap(corner)
      let marker = marker_el.marker

      return marker
    },
    addMarkerCircleToMap(coords, color = 'bg-promy-green-300') {
      var el = document.createElement('div')

      el.innerHTML = `<div  class=' icon-circle ${color} text-center flex  text-xs font-bold  justify-center text-white'></div>`
      let marker = new this.$mapboxgl.Marker(el).setLngLat(coords)
      marker.addTo(this.map)
      return {
        el,
        marker,
      }
    },
    addPopupWithElevation(marker) {
      let popup = new this.$mapboxgl.Popup({
        anchor: 'bottom',
        closeButton: false,
        closeOnClick: false,
      })
      popup.setHTML(
        ` <div class='flex flex-col  p-2'>   <span> ${marker.elevation} M </span> </div> `,
      )
      marker.setPopup(popup)
      marker.togglePopup()
    },
    removeMarkersAltimetrie() {
      this.removeMarkers(this.markers_altimetrie)
    },
  },

  watch: {
    is_altimetrie_active: {
      immediate: true,
      handler(active) {
        this.addCornerCrossMarkersAltimetrie()
      },
    },
    is_altimetries_loading(value) {
      if (!value) {
        this.removeMarkersAltimetrie()
        if (this.is_altimetrie_active) {
          this.addCornerCrossMarkersAltimetrie()
        }
      }
    },
  },
  beforeDestroy() {
    this.removeMarkersAltimetrie()
  },
}
</script>
<style>
.icon-circle {
  @apply flex text-black justify-center text-base items-center font-bold  font-main w-5 h-5 border-solid  border-white border-3;
  border-radius: 50%;
}
</style>
